import { useRef, useEffect, useState, useCallback } from 'react';
import { Box, Button /* , styled */ } from '@mui/material';
import { useParams, useHistory } from 'react-router-dom';
import IframeResizer from '@iframe-resizer/react';
import { useAuth } from 'auth/AuthContext';
import { getOrganizationPath } from 'constants/RoutePath';
import { useOrganization } from 'components/organization/OrganizationProvider';
import { Translate } from 'i18n/Translate';

export const ProjectRegister = () => {
  const iframeRef = useRef(null);
  const [iframeUrl, setIframeUrl] = useState('');

  const {
    current: { organizationId },
  } = useOrganization();

  const { externalReference } = useParams();
  const { token } = useAuth();

  const { push } = useHistory();

  const switchToProjectsView = useCallback(
    () => push(getOrganizationPath(externalReference)),
    [push, externalReference],
  );

  useEffect(() => {
    // Base URL of the Gravity Form
    const baseUrl = `${process.env.REACT_APP_CO2P_FORMS_BASE_URL}/projekt-registrierung/`;

    // Construct URL with parameters
    const urlWithParams = `${baseUrl}?organizationId=${organizationId}&reference=${externalReference}&token=${token}`;

    // Set the constructed URL to state
    setIframeUrl(urlWithParams);
  }, [organizationId, externalReference, token]);

  const onCloseClick = () => {
    switchToProjectsView();
  };

  return (
    <>
      <Box display="flex" justifyContent="flex-start">
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={onCloseClick}
        >
          <Translate text="action.back" />
        </Button>
      </Box>
      <IframeResizer
        id="project"
        forwardRef={iframeRef}
        title="project"
        src={iframeUrl}
        frameBorder="0"
        style={{ width: '100%', height: '500vh' }}
      />
    </>
  );
};

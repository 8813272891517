import { useIntl } from 'react-intl';
import { styled } from '@mui/material';
import { getDomain } from 'utils/utils';

const StyledImg = styled('img')`
  max-height: 60px;
`;

export const AppLogo = ({ className }) => {
  const { formatMessage } = useIntl();

  const domain = getDomain();

  return (
    <StyledImg
      className={className}
      src={
        domain.includes('c02p') || domain.includes('co2p')
          ? `${process.env.PUBLIC_URL}/co2PocketLogo.svg`
          : `${process.env.PUBLIC_URL}/c19tlogo.svg`
      }
      alt={formatMessage({ id: 'alt.app_icon' })}
    />
  );
};

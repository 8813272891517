import { useIntl } from 'react-intl';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Typography, Button, styled } from '@mui/material';
import { BackdropSpinner } from 'components/BackdropSpinner';
import { getOrganizationPath } from 'constants/RoutePath';
import { useCo2Organization } from 'hooks/useCo2Organizations';
import { Translate } from 'i18n/Translate';
import { OrganizationAdd } from './OrganizationAdd';
import { OrganizationsList } from './OrganizationsList';

const CfpLogoContainer = styled('div')`
  display: flex;
  justify-content: center;
  padding-top: 8px;
  width: 100%;
`;

const CfpLogo = styled('img')`
  max-width: 200px;
`;

export const OrganizationData = ({ accountKey }) => {
  const { formatMessage } = useIntl();

  const { push } = useHistory();

  const { data: organizations, isLoading: isFetchingOrganizations } =
    useCo2Organization({
      accountKey,
    });

  const switchToOrganizationDetailsView = useCallback(
    ({ external_reference: externalReference }) =>
      push(getOrganizationPath(externalReference)),
    [push],
  );

  return (
    <>
      <BackdropSpinner isOpen={isFetchingOrganizations} />
      <CfpLogoContainer>
        <CfpLogo
          src={`${process.env.PUBLIC_URL}/co2PocketLogo.svg`}
          alt={formatMessage({ id: 'alt.cfp_logo' })}
        />
      </CfpLogoContainer>
      <Box
        alignItems="center"
        display="flex"
        justifyContent="center"
        flexDirection="column"
        mt={6}
      >
        <Typography variant="h6">
          <Translate text="co2.organization.title" />
        </Typography>
        <Box mb={4} mt={1}>
          <OrganizationsList
            organizations={organizations}
            onRowClick={switchToOrganizationDetailsView}
          />
        </Box>
        <Box mt={2}>
          <OrganizationAdd />
        </Box>
        <Box mt={2}>
          <Button
            component="a"
            href="https://www.co2p.com/"
            rel="noopener noreferrer"
            target="_blank"
            variant="secondary"
            color="secondary"
          >
            <Translate text="cfp.action.show_more" />
          </Button>
        </Box>
      </Box>
    </>
  );
};
